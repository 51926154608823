@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('../styles/root.css');

section#main-slider {
    width: 100vw;
    height: 80vh;
    display: flex;
    margin-bottom: 8vh;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.27) 0%, rgba(0, 0, 0, 0.9) 65%), url('https://ffhouse.com.br/db/images/main-banner.jpeg') no-repeat center center / cover;
}

section#main-slider section.mid-section {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: baseline;
}

section#main-slider h1,
section#main-slider h2 {
    color: var(--global-white-color);
}

section#main-slider h2 {
    display: flex;
    flex-direction: column;
}

section#main-slider h1 {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.5em;
    margin-bottom: 10px;
    text-transform: uppercase;
}

section#main-slider h2 {
    margin-top: 2px;
    font-size: 64px;
    font-weight: 600;
    line-height: 70px;
}

section#main-slider .font-color {
    color: var(--global-main-color);
}

@media (max-width: 1270px) {
    section#main-slider {
        margin-bottom: 5vh;
        background-position: 30%;
    }
}

@media (max-width: 880px) {
    section#main-slider {
        height: 80vh;
        background-position: 14%;
    }

    section#main-slider h1 {
        font-size: 12px;
    }

    section#main-slider h2 {
        font-size: 50px;
        line-height: 55px;
    }
}

@media (max-width: 600px) {
    section#main-slider {
        background-position: 50%;
    }

    section#main-slider h1 {
        font-size: 12px;
    }

    section#main-slider h2 {
        font-size: 30px;
        line-height: 38px;
    }
}