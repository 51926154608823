@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('../styles/root.css');

section#cases {
    width: 100vw;
    height: auto;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 0 0 8vh 0;
    flex-direction: column;
}

section#cases h2.title-section {
    width: 1200px;
}

section#cases .swiper {
    width: 100%;
    height: 100%;
}

section#cases .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

section#cases .swiper-pagination-bullet-active {
    background: var(--global-main-color) !important;
}

section#cases .cases {
    width: 100vw;
    height: 390px;
    margin-top: 15px;
}

section#cases .cases .case {
    height: 390px;
}

section#cases .cases .case .image {
    width: 100%;
    height: 100%;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

section#cases .image-hover {
    width: 100%;
    height: 390px;
    opacity: 0;
    display: flex;
    position: absolute;
    flex-direction: column;
    transition: 0.4s ease-in;
    padding: 20px 20px 30px 20px;
    justify-content: space-between;
}

section#cases .image-hover:hover {
    opacity: 1;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(2, 2, 2, 0.05) 0%, rgba(5, 5, 5, 0.7) 60.5%, rgba(0, 0, 0, 0.9) 88%) !important;
}

section#cases .image-hover button {
    min-width: 220px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    border-radius: 5px;
    align-self: flex-end;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 1vh 2vw 1vh 2vw;
    color: var(--global-white-color);
    transition: background-position 0.3s ease;
    background: linear-gradient(90deg, #E64438 0%, #CF2B1F 100%);
    background-size: 300% 100%;
    background-position: 0 0;
}

section#cases .image-hover button:hover {
    background-position: 100% 0;
}

section#cases .cases .case .texts h4 {
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    line-height: 30px;
    color: var(--global-white-color);
}

section#cases .cases .case .texts h5 {
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    color: var(--global-white-color);
}

@media (max-width: 1270px) {
    section#cases h2.title-section {
        width: 100vw;
        padding: 0 4vw 0 4vw;
    }
}

@media (max-width: 670px) {
    section#cases {
        padding: 0 0 5vh 0;
    }
}