@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('../styles/root.css');

section#why-us {
    width: 100vw;
    height: 120px;
    position: relative;
    margin-bottom: 8vh;
    padding: 0 4vw 0 4vw;
    background-color: var(--global-main-color);
}

section#why-us .overlay {
    width: 160px;
    height: 120px;
    margin-left: 25%;
    position: absolute;
    z-index: 1 !important;
}

section#why-us .overlay #data-overlay {
    width: 100%;
    height: 100%;
    opacity: 0.12;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('https://www.ffhouse.com.br/db/images/logo-overlay.png');
}

section#why-us .content {
    margin: auto;
    width: 1200px;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2 !important;
    justify-content: space-between;
}

section#why-us h1 {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 1px;
    flex-direction: column;
    color: var(--global-white-color)
}

section#why-us .cards {
    display: flex;
    max-width: 1000px;
    flex-direction: row;
    justify-content: space-evenly;
}

section#why-us .card {
    width: 310px;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

section#why-us .card .icon {
    min-width: 45px;
    height: 45px;
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: var(--global-white-color);
}

section#why-us .card h4 {
    font-size: 12px;
    font-weight: 200;
    margin-left: 10px;
    letter-spacing: 0.05em;
    color: var(--global-white-color)
}

@media (max-width: 1240px) {
    section#why-us {
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4vh 4vw 4vh 4vw;
        justify-content: space-around;
    }

    section#why-us .content {
        justify-content: space-evenly;
    }

    section#why-us .cards {
        gap: 20px;
        flex-direction: column;
    }

    section#why-us .overlay {
        width: 30%;
        height: 100%;
        margin-left: -30%;
    }
}

@media (max-width: 670px) {
    section#why-us {
        margin-bottom: 5vh;
    }

    section#why-us .content {
        justify-content: space-between
    }

    section#why-us .overlay {
        width: 40%;
    }
}

@media (max-width: 570px) {
    section#why-us .content {
        gap: 15px;
        align-items: baseline;
        flex-direction: column;
    }

    section#why-us .overlay {
        width: 50%;
        height: 100%;
        margin-left: 45%;
    }
}