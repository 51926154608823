@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('../styles/root.css');

/* ---------- CSS for title section ---------- */
section#contact {
    gap: 20px;
    display: flex;
    flex-direction: column;
}

section#contact .content-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* ---------- CSS for social media ---------- */
section#contact nav {
    gap: 15px;
    display: flex;
    flex-direction: column;
}

section#contact nav .social {
    gap: 12px;
    width: 390px;
    height: 75px;
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    border-radius: 15px;
    padding: 16px 20px 12px 20px;
    border-bottom: 4px solid var(--global-main-color);
    box-shadow: 0 0 20px 2px #1717170d;
}

section#contact .social h6 {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--global-main-black-text);
}

section#contact .social h1 {
    font-size: 18px;
    font-weight: 700;
    color: var(--global-main-black-text);
}

/* ---------- CSS for form ---------- */
form#contact-us {
    gap: 8px;
    display: flex;
    flex-direction: column;
}

form#contact-us title {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-top: -60px;
}

form#contact-us .form-group {
    display: flex;
    flex-direction: column;
}

form#contact-us label {
    font-size: 13px;
    font-weight: 600;
    line-height: 35px;
    color: var(--global-main-color);
}

form#contact-us input,
form#contact-us select {
    width: 435px;
    height: 40px;
    font-size: 13px;
    margin-top: -3px;
    padding-left: 15px;
    border-radius: 10px;
    box-sizing: border-box;
    border-bottom: 2px solid var(--global-main-color);
    box-shadow: 0 0 20px 2px #1717170d;
}

form#contact-us .form-actions {
    gap: 8px;
    display: flex;
    margin-top: 15px;
    flex-direction: column;
}

form#contact-us .form-actions .send-contact {
    width: 435px;
    height: 40px;
    font-size: 14px;
    font-weight: 300;
    border-radius: 5px;
    color: var(--global-white-color);
}

form#contact-us .form-actions .send-contact.email {
    background: linear-gradient(90deg, #E64438 0%, #CF2B1F 100%);
}

form#contact-us .form-actions .send-contact.whatsapp {
    background: linear-gradient(90deg, #0FAC3F 0%, #09AC3B 100%);
}

form#contact-us select#topic {
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%238b96a0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .9rem center;
    background-size: 14px 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
    -moz-padding-start: calc(.9rem - 3px);
}

/* ---------- CSS for responsive section ---------- */
@media (max-width: 950px) {
    section#contact .content-section {
        display: flex;
        flex-direction: column;
    }

    section#contact nav .social {
        width: 100%;
    }

    form#contact-us title {
        display: flex;
        font-size: 20px;
        font-weight: 600;
        margin-top: 40px;
        flex-direction: column;
    }

    form#contact-us input,
    form#contact-us select,
    form#contact-us .form-actions .send-contact {
        width: 100%;
    }
}

@media (max-width: 550px) {
    section#contact h2.title-section {
        font-size: 32px;
        line-height: 25px;
    }

    section#contact .social h6 {
        font-size: 10px;
    }

    section#contact .social h1 {
        font-size: 16px;
    }

    form#contact-us input,
    form#contact-us select {
        font-size: 12px;
    }
}