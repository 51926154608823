@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('../styles/root.css');

section#about-us {
    gap: 3%
}

section#about-us .text {
    max-width: 60%;
}

section#about-us .text h4 {
    gap: 2px;
    display: flex;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    flex-direction: column;
}

section#about-us img.about-us {
    width: 37%;
    height: 600px;
    pointer-events: none;
    background-position: center;
    background-image: url('https://ffhouse.com.br/db/images/banner-contact-us.png');
}

@media (max-width: 1150px) {
    section#about-us h2.title-section {
        gap: 0;
        font-size: 28px;
    }

    section#about-us h2.title-section .small {
        font-size: 14px;
    }

    section#about-us .text h4 {
        gap: 8px;
        display: flex;
        margin-top: 4px;
        font-size: 16px;
        line-height: 24px;
        flex-direction: column;
    }
}

@media (max-width: 1000px) {
    section#about-us {
        gap: 20px;
        flex-direction: column-reverse;
    }

    section#about-us .text {
       max-width: 100%;
    }

    section#about-us img.about-us {
        width: 100%;
        height: 500px;
        pointer-events: none;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('https://ffhouse.com.br/db/images/banner-contact-us.png');
    }
}

@media (max-width: 600px) {
    section#about-us h2.title-section {
        gap: 0;
        font-size: 20px;
    }

    section#about-us .text h4 {
        gap: 5px;
        margin-top: 2px;
        font-size: 14px;
        line-height: 22px;
    }
}