@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('../styles/root.css');

section#our-partners {
    width: 100vw;
    height: 130px;
    display: flex;
    margin: 0 0 8vh 0;
    justify-content: space-between;
}

section#our-partners .partner-left {
    z-index: 5;
    width: 35vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

section#our-partners .partner-right {
    z-index: 5;
    width: 25vw;
    height: 100%;
}

section#our-partners .partner-center {
    width: 40vw;
    height: 100%;
}

section#our-partners .partner-left svg,
section#our-partners .partner-right svg {
    width: 100%;
}

section#our-partners .partner-title {
    overflow: hidden;
    position: absolute;
    padding-right: 6vw;
}

section#our-partners .partner-title h3 {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: var(--global-white-color);
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 1px;
}

section#our-partners .swiper {
    z-index: 1;
    width: 140%;
    height: 100%;
    margin-left: -20%;
}

section#our-partners .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

section#our-partners .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (max-width: 1440px) {
    section#our-partners .partner-title {
        width: 35vw;
        padding-left: 7vw;
    }
}

@media (max-width: 1010px) {
    section#our-partners .partner-title {
        padding-left: 4vw;
    }
}

@media (max-width: 935px) {
    section#our-partners .partner-title {
        width: 45vw;
        padding-left: 4vw;
    }

    section#our-partners .partner-left {
        width: 45vw;
        display: flex;
    }

    section#our-partners .partner-right {
        width: 15vw;
    }
}

@media (max-width: 670px) {
    section#our-partners {
        margin: 0 0 5vh 0;
    }

    section#our-partners .swiper {
        margin-left: -40%;
    }

    section#our-partners .partner-title {
        width: 75vw;
        padding: 0 20vw 0 20vw;
    }

    section#our-partners .partner-title h3 {
        font-size: 14px;
    }

    section#our-partners .partner-left {
        width: 75vw;
    }

    section#our-partners .partner-right {
        display: none;
    }
}