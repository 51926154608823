@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('../styles/root.css');

section#our-solutions {
    height: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
}

section#our-solutions .solutions {
    gap: 55px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 35px;
    justify-content: space-between;
}

section#our-solutions .solution {
    width: 360px;
    height: 445px;
    display: flex;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 20px 2px #1717170d;
    background: var(--global-white-color);
    border-bottom: 4px solid var(--global-main-color);
}

section#our-solutions .solution .content {
    width: 85%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

section#our-solutions .solution .svg-container {
    height: 55px;
    display: flex;
    align-items: center;
}

section#our-solutions .solution h2 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
    color: var(--global-main-black-text);
}

section#our-solutions .solution span {
    display: flex;
}

section#our-solutions .solution p,
section#our-solutions .solution ul {
    font-size: 12.5px;
    font-weight: 500;
    margin-top: 2px;
    text-align: justify;
    color: var(--global-main-black-text);
}

section#our-solutions .solution ul {
    margin-top: 5px;
    list-style-type: none;
}

li.action-item {
    display: flex;
    margin-bottom: 3px;
    align-items: baseline;
}

li.action-item span {
    margin-left: 5px;
}

li.action-item svg {
    flex-shrink: 0;
}

section#our-solutions .solution button {
    width: 100%;
    height: 45px;
    display: flex;
    font-size: 16px;
    font-weight: 300;
    border-radius: 8px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--global-white-color);
    transition: background-position 0.3s ease;
    background: linear-gradient(90deg, #E64438 0%, #CF2B1F 100%);
    background-size: 300% 100%;
    background-position: 0 0;
}

section#our-solutions .solution button:hover {
    background-position: 100% 0;
}

@media (max-width: 1270px) {
    section#our-solutions .solution {
        min-width: 340px;
        flex: 1 1 calc(50% - 55px);
        box-sizing: border-box;
    }
}

@media (max-width: 600px) {
    section#our-solutions .solutions {
        gap: 30px;
        margin-top: 20px;
    }
}