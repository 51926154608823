@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('../styles/root.css');

footer {
    width: 100vw;
    min-height: 40px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 1vh 5vw 1vh 5vw;
    color: var(--global-white-color);
    background-color: var(--global-main-color);
}

footer h6 {
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.02em;
}