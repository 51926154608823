@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('../styles/root.css');

.menu {
    width: 100%;
    height: 125px;
    align-content: center;
    background-color: var(--global-white-color);
}

.menu nav a {
    display: block;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    text-decoration: none;
    padding: 1vh 1.5vh 1vh 1.5vh;
    color: var(--global-main-black-text);
    transition: transform 0.2s ease, font-weight 0.2s ease;
}

.menu #logo {
    pointer-events: none;
}

.menu-icons {
    display: none;
}

.menu .content {
    margin: auto;
    display: flex;
    height: 100%;
    max-width: 1200px;
    position: relative;
    align-items: center;
}

.menu .sub-content {
    right: 0;
    display: flex;
    position: absolute;
    align-items: center;
}

.menu nav ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
}

.menu nav li {
    padding: 1vh;
}

.menu nav a:hover {
    font-weight: 600;
    color: var(--global-main-color);
}

.menu nav a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: var(--global-main-color);
    transition: width 0.3s;
    position: absolute;
    left: 0;
    bottom: -5px;
}

.menu nav a:hover::after {
    width: 100%;
}

.options {
    margin-right: 25px;
}

button.contact-us {
    width: 218px;
    height: 45px;
    font-size: 18px;
    font-weight: 200;
    border-radius: 5px;
    color: var(--global-white-color);
    transition: background-position 0.3s ease;
    background: linear-gradient(90deg, #E64438 0%, #CF2B1F 100%);
    background-size: 300% 100%;
    background-position: 0 0;
}

button.contact-us:hover {
    background-position: 100% 0;
}


@media (max-width: 1330px) {
    .menu .content {
        max-width: 95%;
    }

    .menu nav li {
        padding: -10px;
    }
}

@media (max-width: 1200px) {
    .menu {
        margin: auto;
        height: 120px;
        padding: 0 2.3vh 0 2.3vh;
    }

    .menu .content {
        max-width: 95%;
        height: 100px;
        display: flex;
        padding: 0;
    }

    .menu-icons {
        right: 0;
        cursor: pointer;
        display: flex;
        padding: 1.6vh;
        font-size: 1.5rem;
        position: absolute;
        border-radius: 20px;
        color: var(--global-white-color);
        background-color: var(--global-main-color);
    }

    .menu nav ul {
        display: block;
        padding-top: 12px;
    }

    .menu nav li {
        padding: 0 5px 12px 0;
    }

    .menu .sub-content {
        opacity: 0;
        display: none;
        position: fixed;
        top: 110px;
        width: 300px;
        height: 100vh;
        background: #FFF;
        padding: 10px 0 0 10px;
        box-shadow: -10px 0px 16.4px -11px rgba(0, 0, 0, 0.10);
    }

    .menu .sub-content.active {
        opacity: 1;
        display: block;
        padding: 15px;
        z-index: 1 !important;
        transition: all 1s ease;
    }

    .menu nav a::after {
        margin-bottom: -1vh;
    }

    .menu nav a:hover::after {
        width: 95.5%;
    }

    .menu-close {
        display: none;
    }

    button.contact-us {
        width: 100%
    }
}

@media (max-width: 760px) {
    .menu .sub-content {
        top: 100px;
    }
}

@media (max-width: 510px) {
    .menu #logo {
        width: 190px;
    }
}