@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('../styles/root.css');

section.mid-section {
    height: auto;
    max-width: 1200px;
    margin: auto;
    display: flex;
    padding-bottom: 8vh;
}

h2.title-section {
    gap: 8px;
    display: flex;
    font-size: 38px;
    font-weight: 600;
    line-height: 28px;
    flex-direction: column;
    color: var(--global-main-black-text);
}

h2.title-section .small {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    color: var(--global-main-color);
}


@media (max-width: 1270px) {
    section.mid-section {
        padding: 0 4vw 8vh 4vw;
    }
}

@media (max-width: 880px) {
    section#our-solutions h2.title-section,
    section#cases h2.title-section {
        font-size: 28px;
        line-height: 22px;
    }
}

@media (max-width: 670px) {
    section.mid-section {
        padding: 0 4vw 5vh 4vw;
    }
}

@media (max-width: 600px) {
    section#our-solutions h2.title-section,
    section#cases h2.title-section {
        font-size: 20px;
        line-height: 15px;
    }

    section#about-us h2.title-section .small,
    section#our-solutions h2.title-section .small,
    section#cases h2.title-section .small {
        font-size: 14px;
    }
}

@media (max-width: 550px) {
    h2.title-section {
        font-size: 32px;
        line-height: 25px;
    }
}